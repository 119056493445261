window.contactForm = function () {
  const scriptURLC =
    "https://script.google.com/macros/s/AKfycbwEwdqWt4K-48T5V723NB6wmi3coP-zf0nMPXXfuzQI0uXZbFh6DDAt1NFqcNK3Nt3t/exec";
  return {
    message: "",
    loading: false,
    contactButtonLabel:
      document.documentElement.lang === "en" ? "Send" : "Odeslat",

    submitData() {
      if (document.documentElement.lang === "en") {
        this.contactButtonLabel = "Sending...";
      } else {
        this.contactButtonLabel = "Odesílám...";
      }
      this.loading = true;
      this.message = "";
      const contactForm = document.forms["contact-form"];

      fetch(scriptURLC, {
        method: "POST",
        body: new FormData(contactForm),
      })
        .then((response) => {
          return response
            .json()
            .then((data) => {
              console.log(data);
              if (data.result && data.result === "success") {
                contactForm.reset();
                if (document.documentElement.lang === "en") {
                  this.message = "The message was sucessfully sent!";
                } else {
                  this.message = "Zpráva byla úspěšně odeslána";
                }
              } else {
                if (document.documentElement.lang === "en") {
                  this.message = "Ooops! Something went wrong!";
                } else {
                  this.message = "Ooops! Něco se nepovedlo.";
                }
              }
              return data;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          if (document.documentElement.lang === "en") {
            this.message = "Ooops! Something went wrong!";
          } else {
            this.message = "Ooops! Něco se nepovedlo.";
          }
        })
        .finally(() => {
          this.loading = false;
          if (document.documentElement.lang === "en") {
            this.contactButtonLabel = "Send";
          } else {
            this.contactButtonLabel = "Odeslat";
          }
        });
    },
  };
};
